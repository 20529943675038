@font-face {
  font-family: "avenirNextHeavyItalic";
  src: url("./AvenirNext-HeavyItalic.otf");
}

@font-face {
  font-family: "avenirDemiBold";
  src: url("./AvenirNext-DemiBold.otf");
}

.outer-circle {
  background-color: #fff6;
  height: 75px;
  left: 45%;
  bottom: 6rem;
  width: 75px;
  z-index: 1;
  border-radius: 50%;
  position: relative;
}

.container-circles {
  bottom: 76px;
  left: 50%;
  position: absolute;
}

.inner-circle {
  background: #fff;
  height: 44px;
  left: 50%;
  margin: -22px 0 0 -22px;
  top: 38px;
  width: 44px;
  z-index: 2;
  border-radius: 50%;
  position: absolute;
}
  
.App1{
  padding: 0 1.8rem;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #161615;
  height:  100vh;
  background-repeat: no-repeat;
  background-size: cover;
  
}

.App2 {
  padding: 0 1.8rem;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #161615;
  min-height: 100vh;
}

.App4{
  padding: 0 1.8rem;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #161615;
  min-height: 100vh;
}


.App3 {
  padding: 0 1.8rem;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #161615;
  min-height: 100vh;
}

.App-MainLogo {
  margin-top: 1.2rem;
  height: 13vh;

}

.progress-bar{
  background-color:white; 
  position:relative; 
  top:0.5rem;
  width:190px;
}

.App-MainLogo2 {
  margin-top: 1.2rem;
  height: 8vh;

}

.App-MainLogo3 {
  margin-top: 3rem;
  height: 12vh;
}

.upload-img{
  height: 4vmin;
  position: relative;
  top: 1.2rem; 
  left: 1.5rem; 
  float: left;
}

.firstViewText {
  font-family:avenirDemiBold,Arial,sans-serif;
  font-size: 0.9rem;
  line-height: 1rem;
}
.firstViewTitle {
  color: #fed303;
  font-size: 1rem;
  margin: 1rem 0 0.5rem 0; 
  font-family:avenirNextHeavyItalic,Arial,sans-serif;
  font-weight: 600;
  
}
.insta{
  font-size: 3rem; 
  color: black;
}

.secondViewTitle {
  font-family:avenirNextHeavyItalic,Arial,sans-serif;
  font-size: 1.4rem;
  color: black;
  margin-top: 2rem;
  font-weight: 600;
}

.secondViewText {
  font-family:avenirDemiBold,Arial,sans-serif;
  font-size: 1.2rem;
  color: black;
  margin-top: 1rem;
  
}

.lastViewText {
  font-family:avenirDemiBold,Arial,sans-serif;
  font-size: 1rem;
  color: white;
  margin-top: 0.5rem;
  
}

.lastViewText2 {
  font-family:avenirDemiBold,Arial,sans-serif;
  font-size: 1.5rem;
  color: white;

  
}

.thirdViewText {
  font-family:avenirDemiBold,Arial,sans-serif;
  font-size: 1rem;
  color: #fed303;
  margin-top: 0.3rem;
  
}

.fourViewText {
  font-family:avenirDemiBold,Arial,sans-serif;
  font-size: 1rem;
  color: white;
  margin-top: 4.5rem;
  
}

.fiveViewText {
  font-family:avenirDemiBold,Arial,sans-serif;
  font-size: 1.3rem;
  color: white;
  margin-top: 5rem;
  
}

.fiveViewTextInstagram {
  font-family:avenirDemiBold,Arial,sans-serif;
  font-size: 1.3rem;
  color: black;
  margin-top: 5rem;
  
}


.upload-image-main-container-done{
  background-color: white; 
  border-radius:30px; 
  width:100%; 
  position: relative; 
  top:1.5rem;
  right: 0.5rem;
  
  
}

.camera-yellow {
  margin-top: 1.5rem; 
  height: 20vmin;
  
}

.upload-image-container {
  cursor: pointer;
  display:inline-block; 
  border:1px solid black; 
  width:50vw; 
  border-radius:6px; 
  margin-top: 0.5rem;
}



.anni-60-cover{
  height: 36vh;
}

.trasforma-button {
  cursor: pointer;
  background-color: #fed303;
  width: 180px;
  height: 40px;
  border-radius: 30px;
  position: relative;
  top:1.5rem;
  font-size: 1.2rem;
  outline:none; 
  border:none;
  color: black;
  font-weight: 700
}

.trasforma-button-2 {
  cursor: pointer;
  background-color: black;
  width: 160px;
  height: 35px;
  border-radius: 30px;
  position: relative;
  font-size: 1rem;
  outline:none; 
  border:none;
  color: white;
  font-weight: 700
}

.carica-foto{
  font-size: 1rem;
  color: black;
  font-family:avenirDemiBold,Arial,sans-serif;
  margin-left:1rem;
}

.upload-image-main-container {
  margin-top:0;
  position: relative;
  top: 0.8rem;
  left: 13%;
  width:70%; 
  height:35vh; 
  padding:0.6rem 0.4rem 0.6rem 0.6rem;
  justify-content: center;
  background-color: white; 
  border-radius:30px; 
  box-shadow: 6px 6px 6px grey;
}

.upload-image-main-container-done {
  margin-top:0;
  position: relative;
  top: 1rem;
  left: 3%;
  width:90%; 
  height:60vh; 
  padding:0.6rem 0.4rem 0.6rem 0.6rem;
  justify-content: center;
  background-color: white; 
  border-radius:30px; 
  box-shadow: 6px 6px 6px grey;
}


.image-cover {
  height: 13vh;
  width: 55vw;
  margin-top:3rem
}

.image-cover-3 {
  height: 18vh;
  width: 80vw;
  margin-top:1.2rem
}

.image-cover-4 {
  height: 35vh;
  width: 75vw;
  
}

.image-cover-2{
  height: 20vh;
  width: 60vw;
  margin-top:1.5rem
}

.App-logoL2R {
  height: 20vmin;
  pointer-events: none;
}

.App-logoR2L {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.App-header1 {
  display: flex;
  align-items: left;
  justify-content: left;
}

.App-header2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.App-header3 {
  display: flex;
  flex-direction: row;
  
}


.logos { 
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

.App-link {
  color: #f1a20d;
}

.button-anni {
  font-family:avenirDemiBold,Arial,sans-serif;
  cursor: pointer;
  background-color: #fed303;
  width: 200px;
  height: 60px;
  border-radius: 30px;
  margin-bottom: 1rem; 
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
  color:black; 
  outline:none; 
  border:none 
}

.loading-text{
  margin:0;
  padding:0;
  font-size: 9pt;
  font-weight: bold;
  color:white;
  font-family:avenirDemiBold,Arial,sans-serif;
}

.pronti-per{
  color: white; 
  font-size: 13pt; 
  margin-top: 4rem;
  font-family: avenirDemiBold,Arial,sans-serif
}

.per-rivivere{
  color: white; 
  font-size: 13pt;
  font-family: avenirDemiBold,Arial,sans-serif;
}

.stay-tuned{
  color: #fed303;
  font-size: 20pt;
  font-weight: bold;
  margin-top: 1rem; 
  font-family: avenirDemiBold,Arial,sans-serif;
}

.lattine-home{
  margin: 0; 
  height: 25vh; 
}

@media (max-width:375px) {
  .carica-foto {
    font-size:0.8rem; 
  }
  .upload-image-container {
    margin-top: 0;
  }
  .camera-yellow{
    margin-top: 1rem; 
  }
  .upload-img{
    top: 1rem; 
  }
}

/* TABLET */
@media (min-width:768px) {
  .firstViewTitle{
    font-size: 2.8rem;
    margin-bottom: 2rem;
  }
  .firstViewText {
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
  .secondViewTitle{
    margin-top: 3rem;
    font-size: 2.2rem;
  }
  .secondViewText{
    margin-top: 1rem;
    font-size: 2rem;
    color:black
  }
  .upload-image-main-container {
    margin-top:2rem;
    position: relative;
    right: 2rem;
    width:70vw; 
    height:40vh; 
    padding-top:4rem;
    padding-bottom:0;
  }
  
  .upload-image-main-container-done{
    margin-top:5rem;
    height:62vh; 
    
  }

  .upload-img{
    height: 4vmin;
    position: relative;
    top: 0.6rem; 
    left: 1.5rem; 
    float: left;
  }

  .image-cover-3{
    height: 30vh;
    width: 80vw;
  }
 
  
  .lastViewText{
    color:white;
  }
  .fourViewText{
    color: white;
    font-size: 2rem;
  }
  .fiveViewTextInstagram{
    color:white;
    font-size: 2rem;
  }
  .insta{
    font-size: 6rem !important; 
    color: white;
  }
  .pronti-per{
    font-size: 2rem;
  }
  .per-rivivere{
    font-size: 2rem;
  }
  .stay-tuned{
    font-size: 2.8rem;
  }
  .lattine-home{
    height: 32vh; 
  }
}

/* DESKTOP */
  @media (min-width:1281px) {
    .insta{
      font-size: 3rem; 
      color: black;
    }
      .App3 {
        height: 100vh;
      }
      .App2{
        height: 100vh;
      }
      .firstViewTitle{
        font-size: 2.5rem;
        margin-bottom: 2rem;
      }
      .firstViewText {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      .secondViewTitle{
        margin-top: 0;
        font-size: 2.2rem;
      }
      .secondViewText{
        margin-top: 0;
        font-size: 1.8rem;
        color:black
      }
      .App-MainLogo {
        margin-top: 2.5rem;
        height: 18vh;
      
      }
      .anni-60-cover{
        height: 27rem;
      }
      
      
      .button-anni{
        width: 300px;
        height: 60px;
        font-size: 1.4rem;
        margin-top: 3rem;
      }
      .upload-image-main-container {
        margin-top:0;
        position: relative;
        right: 2rem;
        width:27vw; 
        height:65vh; 
        padding-top:0;
        padding-bottom:0;
      }
      .camera-yellow{
        margin-top: 5rem;
        height: 20vmin;
        cursor: pointer;
      }
      .upload-image-container{
        width:18rem; 
        margin-top: 13rem;
      }
      .image-cover {
        height: 16vh;
        position: relative;
        top:4rem
      }
      .trasforma-button{
        width: 200px;
        height: 45px;
        top: 2rem;
      }
      .loading-text{
        font-size: 20pt;
      }

      .progress-bar {
        width: 300px;
      }

      .outer-circle {
        bottom: 12rem;
        left:48%;
      }
      
      
      
}


